import * as React from 'react';
import * as S from './Layout.styled';
import { Navigate, useLocation } from 'react-router-dom';
import { decodeJWT, getCookie } from '@common/functions';
import { useAuth } from '@src/app/hooks';
import { useEffect } from 'react';
import Menu from '@components/Menu';

type IProps = {
  children: JSX.Element
}

function Layout({
  children,
}: IProps): JSX.Element {
  const authContext = useAuth();
  const location = useLocation();

  useEffect(() => {
    const token = getCookie('token');

    if (token) {
      const decodedJwt = decodeJWT(token);

      if (decodedJwt.exp * 1000 < Date.now()) {
        authContext?.onLogout();
      }
    } else {
      authContext?.onLogout();
    }
  }, [authContext, location]);

  if (!authContext?.token) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return (
    <S.Wrapper>
      <Menu />
      <S.Content>
        {children}
      </S.Content>
      <S.ExitButton onClick={authContext?.onLogout} />
    </S.Wrapper>
  );
}

export default Layout;
