import styled from 'styled-components';

export const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: none;
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: rgba(0,0,0,0.5);
`;

export const Dialog = styled.div<{ $width: number; }>`
  position: relative;
  width: 100%;
  max-width: ${({ $width }) => `${$width}px`};
  background: #FFF;
  box-shadow: 0 4px 4px rgba(171, 181, 209, 0.2);
  border-radius: 24px;
  padding: 48px 58px;
  margin: 10vh auto;
  pointer-events: auto;
  &+& {
    margin-top: 32px;
  }
`;

export const Close = styled.button`
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  top: 35px;
  right: 35px;
`;
