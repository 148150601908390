import * as React from 'react';
import * as S from '@components/Monitoring.styled';
import { selectConfig } from '@src/redux/slices/ConfigSlice';
import { useAppSelector, useAuth, useWindowSize } from '@src/app/hooks';
import { useCallback, useEffect, useState } from 'react';
import CheckGrey from '@src/images/check-grey.svg';
import Close from '@src/images/close.svg';
import Dropdown from '@components/Dropdown';
import Edit from '@src/images/edit.svg';
import Layout from '@components/Layout';
import Pagination from '@components/Pagination';

export type TTerminal = {
  id: string,
  activated: boolean,
  terminalOnline: boolean,
  auraOnline: boolean,
  name: string,
  command?: Record<string, unknown>,
}

const itemsPerPage = 5;

function Monitoring(): JSX.Element {
  const authContext = useAuth();
  const size = useWindowSize();
  const config = useAppSelector(selectConfig);
  const [terminals, setTerminals] = useState<Array<TTerminal>>([]);
  const [page, setPage] = useState<number>(1);
  const [timer, setTimer] = useState<NodeJS.Timer>();
  const [editingId, setEditingId] = useState<string>('');
  const [editingName, setEditingName] = useState<string>('');

  const fetchTerminals = useCallback(async (signal?: AbortSignal) => {
    const response = await fetch(`${window.API_DOMAIN}/api/user/terminals?page=${page}&itemsPerPage=${itemsPerPage}`, {
      headers: {
        authorization: `Bearer ${authContext?.token}`,
      },
      signal,
    });

    if (response?.status === 401) {
      authContext?.onLogout();
    } else {
      const responseData = await response.json();
      setTerminals(responseData);
    }
  }, [authContext, page]);

  const fetchEditName = useCallback(async () => {
    const response = await fetch(`${window.API_DOMAIN}/api/user/terminals/${editingId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/merge-patch+json',
        authorization: `Bearer ${authContext?.token}`,
      },
      body: JSON.stringify({
        name: editingName,
      }),
    });

    if (response?.status === 401) {
      authContext?.onLogout();
    } else {
      setEditingId('');
      setEditingName('');
      fetchTerminals();
    }
  }, [authContext, editingId, editingName, fetchTerminals]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchTerminals(abortController.signal);
    if (timer) {
      clearInterval(timer);
    }
    const timerId = setInterval(() => {
      fetchTerminals(abortController.signal);
    }, (config.timeRefreshData || 5) * 1000);
    setTimer(timerId);

    return () => {
      clearInterval(timerId);
      abortController.abort();
    };
  }, [fetchTerminals]);

  const getNameCell = (terminal: TTerminal) => (
    <S.NameCell colSpan={size.width < 768 ? 3 : 1}>
      {editingId === terminal.id
        ? (
          <S.NameForm>
            <S.NameInput
              value={editingName}
              onChange={event => setEditingName(event.target.value)}
              type="text"
            />
            <S.NameButton
              type="button"
              onClick={() => {
                setEditingId('');
                setEditingName('');
              }}
            >
              <img src={Close} alt="Close" />
            </S.NameButton>
            <S.NameButton type="button" onClick={fetchEditName}>
              <img src={CheckGrey} alt="Edit" />
            </S.NameButton>
          </S.NameForm>
        ) : (
          <S.NameWrapper>
            {terminal.name}
            <S.EditButton
              type="button"
              onClick={() => {
                setEditingId(terminal.id);
                setEditingName(terminal.name);
              }}
            >
              <img src={Edit} alt="Edit" />
            </S.EditButton>
          </S.NameWrapper>
        )}
    </S.NameCell>
  );

  return (
    <Layout>
      <S.Wrapper>
        <S.TableWrapper>
          <S.Table>
            <thead>
              <tr>
                {size.width >= 1200 && <th>ID</th>}
                {size.width >= 768 && <th>Название</th>}
                <th>Терминал</th>
                <th>BM Aura</th>
                <th>Опции</th>
              </tr>
            </thead>
            <tbody>
              {terminals.map(terminal => (
                <React.Fragment key={`${terminal.id}_name`}>
                  {size.width < 768 && (
                    <tr>
                      {getNameCell(terminal)}
                    </tr>
                  )}
                  <tr>
                    {size.width >= 1200 && <td>{terminal.id}</td>}
                    {size.width >= 768 && getNameCell(terminal)}
                    <td>
                      <S.Status
                        status={(terminal.terminalOnline ? 'active' : 'inactive')}
                      >
                        {(terminal.terminalOnline ? 'Включен' : 'Выключен')}
                      </S.Status>
                    </td>
                    <td>
                      <S.Status
                        status={(terminal.auraOnline ? 'active' : 'inactive')}
                      >
                        {(terminal.auraOnline ? 'Включена' : 'Выключена')}
                      </S.Status>
                    </td>
                    <td>
                      <Dropdown terminal={terminal} />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </S.Table>
        </S.TableWrapper>
        <Pagination itemsPerPage={itemsPerPage} page={page} setPage={setPage} />
      </S.Wrapper>
    </Layout>
  );
}

export default Monitoring;
