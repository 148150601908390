import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 554px;
  margin: 0 auto;
  
  @media (min-width: 1200px) {
    margin: 0 0 0 15vw;
  }
`;

export const Title = styled.div`
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    margin-bottom: 48px;
  }

  @media (min-width: 1024px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

export const Form = styled.form`
  
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
  gap: 18px;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 32px;
  }
  
  > * {
    flex: 1 1 auto;
  }
`;

export const Footer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
    margin-top: 48px;
  }

  button {
    @media (max-width: 767.9px) {
      flex: 1 1 auto;
    }
  }

  button + button {
    margin-left: 8px;

    @media (min-width: 768px) {
      margin-left: 24px;
    }
  }
`;

export const Feedback = styled.div`
  text-align: center;

  button {
    @media (max-width: 767.9px) {
      width: 100%;
    }
  }
`;

export const FeedbackMessage = styled.div`
  margin-bottom: 24px;
`;

export const FeedbackID = styled.div`
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    margin-bottom: 56px;
  }
`;
