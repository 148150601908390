import * as React from 'react';
import * as S from './Support.styled';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { decodeJWT } from '@common/functions';
import { useAuth } from '@src/app/hooks';
import Button from '@ui/Button';
import Input from '@ui/Input';
import Layout from '@components/Layout';

type TFormData = {
  name: {
    value: string,
    error: string,
  },
  email: {
    value: string,
    error: string,
  },
  phone: {
    value: string,
    error: string,
  },
  text: {
    value: string,
    error: string,
  },
}

const defaultData: TFormData = {
  name: {
    value: '',
    error: '',
  },
  email: {
    value: '',
    error: '',
  },
  phone: {
    value: '',
    error: '',
  },
  text: {
    value: '',
    error: '',
  },
};

type TViolation = {
  message: string;
  propertyPath: string;
}

function Support(): JSX.Element {
  const authContext = useAuth();
  const [data, setData] = useState<TFormData>(defaultData);
  const [feedbackId, setFeedbackId] = useState<number>();
  const [userEmail] = useState<string>(() => {
    if (authContext?.token) {
      const decodedJwt = decodeJWT(authContext.token);
      return decodedJwt.username;
    }
    return '';
  });

  useEffect(() => {
    setData(prevState => ({
      ...prevState,
      ...{
        email: {
          value: userEmail,
          error: '',
        },
      },
    }));
  }, [userEmail]);

  const sendMessage = useCallback(async (event: FormEvent) => {
    event.preventDefault();
    const response = await fetch(`${window.API_DOMAIN}/api/user/feedback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${authContext?.token}`,
      },
      body: JSON.stringify({
        name: data.name.value,
        email: data.email.value,
        phone: data.phone.value,
        text: data.text.value,
      }),
    });

    if (response?.status === 401) {
      authContext?.onLogout();
    } else if (response.status === 200 || response.status === 201) {
      const responseData = await response.json();
      setFeedbackId(responseData.id);
    } else {
      const responseData = await response.json();
      const violations = responseData.violations as Array<TViolation>;
      setData(prevState => (
        {
          name: {
            value: prevState.name.value,
            error: violations.find(violation => violation.propertyPath === 'name')?.message || '',
          },
          email: {
            value: prevState.email.value,
            error: violations.find(violation => violation.propertyPath === 'email')?.message || '',
          },
          phone: {
            value: prevState.phone.value,
            error: violations.find(violation => violation.propertyPath === 'phone')?.message || '',
          },
          text: {
            value: prevState.text.value,
            error: violations.find(violation => violation.propertyPath === 'text')?.message || '',
          },
        }
      ));
    }
  }, [authContext, data]);

  return (
    <Layout>
      <S.Wrapper>
        <S.Title>{feedbackId ? 'Обращение успешно отправлено!' : 'Форма обратной связи'}</S.Title>
        {feedbackId ? (
          <S.Feedback>
            <S.FeedbackMessage>
              Ваше обращение поступило в&nbsp;сервисно-гарантийный центр и&nbsp;будет рассмотрено
              в&nbsp;установленном порядке. Номер обращения:
            </S.FeedbackMessage>
            <S.FeedbackID>ID {feedbackId}</S.FeedbackID>
            <Button
              padding={42}
              onClick={() => {
                setFeedbackId(undefined);
                setData(defaultData);
              }}
              text="Новое обращение"
            />
          </S.Feedback>
        ) : (
          <S.Form onSubmit={sendMessage}>
            <S.FormItem>
              <Input
                name="name"
                value={data.name.value}
                error={data.name.error}
                placeholder="Имя"
                type="text"
                onChange={event => setData(prevState => ({
                  ...prevState,
                  ...{
                    name: {
                      value: event.target.value,
                      error: '',
                    },
                  },
                }))}
              />
            </S.FormItem>
            <S.FormItem>
              <Input
                name="email"
                value={data.email.value}
                error={data.email.error}
                placeholder="E-mail"
                type="text"
                onChange={event => setData(prevState => ({
                  ...prevState,
                  ...{
                    email: {
                      value: event.target.value,
                      error: '',
                    },
                  },
                }))}
              />
              <Input
                name="phone"
                value={data.phone.value}
                error={data.phone.error}
                placeholder="Телефон"
                type="text"
                onChange={event => setData(prevState => ({
                  ...prevState,
                  ...{
                    phone: {
                      value: event.target.value,
                      error: '',
                    },
                  },
                }))}
              />
            </S.FormItem>
            <S.FormItem>
              <Input
                name="text"
                value={data.text.value}
                error={data.text.error}
                placeholder="Сообщение"
                rows={9}
                multiline
                onChange={event => setData(prevState => ({
                  ...prevState,
                  ...{
                    text: {
                      value: event.target.value,
                      error: '',
                    },
                  },
                }))}
              />
            </S.FormItem>
            <S.Footer>
              <Button
                type="reset"
                onClick={() => {
                  setData(() => ({
                    ...defaultData,
                    ...{
                      email: {
                        value: userEmail,
                        error: '',
                      },
                    },
                  }));
                }}
                padding={24}
                text="Сбросить"
              />
              <Button type="submit" padding={24} text="Отправить" />
            </S.Footer>
          </S.Form>
        )}
      </S.Wrapper>
    </Layout>
  );
}

export default Support;
