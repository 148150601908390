import styled from 'styled-components';

export const ExitButton = styled.button`
  padding: 16px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  background: linear-gradient(108.18deg, rgba(255, 255, 255, 0.5) -3.32%, rgba(255, 255, 255, 0) 102.86%),
    linear-gradient(288.5deg, rgba(255, 255, 255, 0.5) -23.92%, rgba(255, 255, 255, 0.3) 103.97%);
  border: 1px solid;
  border-image-source: linear-gradient(288.5deg, rgba(255, 255, 255, 0.5) -23.92%, rgba(255, 255, 255, 0.3) 103.97%);
  border-radius: 83px;
  font-weight: 300;
  font-size: 20px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.white};
  font-family: 'Cera Pro', sans-serif;
  transition: .3s ease-out;

  &:hover {
    opacity: .8;
  }
`;

export const LinkIcon = styled.img`
  margin-right: 16px;
`;
