import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import configSliceReducer from '@src/redux/slices/ConfigSlice';
import dialogSliceReducer from '@src/redux/slices/DialogSlice';

export const store = configureStore({
  reducer: {
    dialog: dialogSliceReducer,
    config: configSliceReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;
