import styled from 'styled-components';

export const Pagination = styled.div`
  display: flex;
  gap: 16px;
  padding: 12px 16px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 16px;
  margin-top: 32px;
`;

export const Button = styled.button<{ isActive?: boolean; }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  ${({ theme, isActive }) => (isActive ? `
    color: ${theme.pagination.text.active};
    background: ${theme.pagination.background.active};
  ` : `
    color: ${theme.pagination.text.default};
    background: ${theme.pagination.background.default};
  `)}
  
  &:disabled {
    opacity: .5;
    cursor: not-allowed;
  }
`;
