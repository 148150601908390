import { TLoginFormData } from '@components/Login';
import { decodeJWT, deleteCookie, getCookie, setCookie } from '@common/functions';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { Dispatch, SetStateAction, createContext, useCallback, useMemo, useState } from 'react';

type TProps = {
  children: React.ReactNode,
}

type TAuthContext = {
  token: string | null,
  onLogin: (data: TLoginFormData, setData: Dispatch<SetStateAction<TLoginFormData>>) => void,
  onLogout: () => void,
}

export const AuthContext = createContext<TAuthContext | null>(null);

function AuthProvider({ children }: TProps): JSX.Element {
  const [token, setToken] = useState<string | null>(getCookie('token'));
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = useCallback(async (data: TLoginFormData, setData: Dispatch<SetStateAction<TLoginFormData>>) => {
    const response = await fetch(`${window.API_DOMAIN}/api/user/authentication_token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
      body: JSON.stringify({
        email: data.login.value,
        password: data.password.value,
      }),
    });
    const responseData = await response.json();

    if (response?.status === 401) {
      setData(prevState => ({
        ...prevState,
        authError: responseData?.message,
      }));
    } else {
      const decodedJWT = decodeJWT(responseData?.token);
      setCookie('token', responseData?.token, data.rememberMe.value ? {
        expires: new Date(decodedJWT.exp * 1000),
      } : {});
      setToken(responseData?.token);
      const origin = location.state?.from?.pathname || '/';
      navigate(origin);
    }
  }, [location.state?.from?.pathname, navigate]);

  const handleLogout = () => {
    deleteCookie('token');
    setToken(null);
  };

  const value = useMemo(() => ({
    token,
    onLogin: handleLogin,
    onLogout: handleLogout,
  }), [handleLogin, token]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
