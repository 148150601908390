import * as React from 'react';
import * as S from '@components/Dialog.styled';
import { closeAllModal, closeModal, selectDialog } from '@src/redux/slices/DialogSlice';
import { useAppDispatch, useAppSelector } from '@src/app/hooks';
import { useEffect } from 'react';
import dialogClose from '@src/images/dialog-close.svg';

function Dialog(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const dialogs = useAppSelector(selectDialog);

  useEffect(() => {
    dialogs.forEach(dialog => dialog.onOpen?.());
  }, [dialogs]);

  return dialogs.length ? (
    <>
      <S.Modal>
        {dialogs.map(dialog => (
          <S.Dialog key={dialog.id} $width={dialog.properties.width}>
            {dialog.showCloseButton && (
              <S.Close type="button" onClick={() => dispatch(closeModal(dialog.id))}>
                <img src={dialogClose} alt="" />
              </S.Close>
            )}
            {dialog.body}
          </S.Dialog>
        ))}
      </S.Modal>
      <S.Backdrop onClick={() => dispatch(closeAllModal())} />
    </>
  ) : null;
}

export default Dialog;
