import * as React from 'react';
import * as S from '@components/Study.styled';
import { useAuth } from '@src/app/hooks';
import { useCallback, useEffect, useState } from 'react';
import Iocn from '@src/images/iocn.svg';
import Layout from '@components/Layout';

type TStudies = {
  id: string;
  video: string;
  linkPresentation: string;
}

function Study(): JSX.Element {
  const authContext = useAuth();
  const [studies, setStudies] = useState<Array<TStudies>>([]);
  const fetchStudies = useCallback(async (signal?: AbortSignal) => {
    const response = await fetch(`${window.API_DOMAIN}/api/user/study`, {
      headers: {
        authorization: `Bearer ${authContext?.token}`,
      },
      signal,
    })
      .then(x => x.json());

    if (response?.code === 401) {
      authContext?.onLogout();
    } else {
      setStudies(response);
    }
  }, [authContext]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchStudies(abortController.signal);

    return () => {
      abortController.abort();
    };
  }, [fetchStudies]);

  return (
    <Layout>
      <S.Wrapper>
        {studies.map(study => (
          <S.Wrapper key={study.id}>
            <S.Iframe data={`${window.API_DOMAIN}/${study.video}`} />
            <S.Footer>
              <S.Button href={`${window.API_DOMAIN}/${study.linkPresentation}`} target="_blank">
                <img src={Iocn} alt="" />
                <span>Посмотреть презентацию</span>
              </S.Button>
            </S.Footer>
          </S.Wrapper>
        ))}
      </S.Wrapper>
    </Layout>
  );
}

export default Study;
