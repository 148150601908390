import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  margin-bottom: 32px;
`;

export const Label = styled.label`
  font-family: 'Cera Pro', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  position: relative;
  padding-left: 40px;
  display: inline-block;
  color: ${({ theme }) => theme.input.text};
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background: linear-gradient(108.18deg, #FFFFFF -3.32%, rgba(255, 255, 255, 0.5) 102.86%);
    border: 1px solid ${({ theme }) => theme.input.border.default};
    box-shadow: 0 4px 4px rgba(171, 181, 209, 0.2);
    backdrop-filter: blur(6px);
    border-radius: 8px;
    transition: ${({ theme }) => theme.generateTransition('border')};
  }
  
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  &:hover:before {
    border: 1px solid ${({ theme }) => theme.input.border.hover};
  }
`;

export const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  
  &:checked + ${Label}:after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMS40MTM2IDAuMTQ2NTY5TDEyLjM1NDcgMS4wODQ4QzEyLjU0OTUgMS4yNzkxMSAxMi41NDgzIDEuNTkyOSAxMi4zNTE5IDEuNzg1NjlMNS4xNDQ4NSA4Ljg1NjI0QzQuOTQ4MzcgOS4wNDkwMiA0LjYzMTEgOS4wNDc3NCA0LjQzNjIxIDguODUzNDNMMC42NDUzNTkgNS4wNzM5MUMwLjQ1MDQzMiA0Ljg3OTU2IDAuNDUxNzI2IDQuNTY1NzcgMC42NDgxOTkgNC4zNzMwM0wxLjU5NjkgMy40NDIzMUMxLjc5MzM4IDMuMjQ5NTcgMi4xMTA2NSAzLjI1MDg1IDIuMzA1NTQgMy40NDUxNkw0LjgwMjM1IDUuOTM0NTRMMTAuNzA1IDAuMTQzNzYxQzEwLjkwMTQgLTAuMDQ5MDIzIDExLjIxODcgLTAuMDQ3NzQyOCAxMS40MTM2IDAuMTQ2NTY5WiIgZmlsbD0iIzQ4NDc0OCIvPgo8L3N2Zz4K)
  }
`;
