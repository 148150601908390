import styled from 'styled-components';

export const Button = styled.button<{ padding: number }>`
  color: ${({ theme }) => theme.colors.text};
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  box-shadow: 0 4px 4px rgba(171, 181, 209, 0.2);
  background: linear-gradient(108.18deg, rgba(255, 255, 255, 0.5) -3.32%, rgba(255, 255, 255, 0) 102.86%),
    linear-gradient(288.5deg, rgba(255, 255, 255, 0.5) -23.92%, rgba(255, 255, 255, 0.3) 103.97%);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 15px ${({ padding }) => padding}px;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background: linear-gradient(242.85deg, #E95732 5.15%, #FBC549 84.91%);
  }
`;
