import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
`;

export const Logo = styled.img`
  max-height: 80px;
  display: block;
  margin: 0 auto 48px;

  @media (min-width: 768px) {
    max-height: unset;
  }
`;

export const Title = styled.div`
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 32px;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 544px;
  margin: 0 auto;
  text-align: center;
  padding: 0 16px;
  
  button {
    @media (max-width: 767.9px) {
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    max-width: 294px;
  }
`;

export const FormItem = styled.div`
  margin-bottom: 32px;

  @media (min-width: 768px) {
    margin-bottom: 24px;
  }
`;

export const ResetLink = styled.a`
  font-weight: 400;
  margin-bottom: 32px;
  display: inline-block;
`;

export const ResetDialog = styled.div`
  p {
    margin: 0 0 16px;
  }
`;

export const Error = styled.div`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.error};
  margin-bottom: 32px;

  @media (min-width: 768px) {
    margin-bottom: 24px;
  }
`;
