import styled from 'styled-components';

export const Input = styled.input<{ hasError: boolean }>`
  border: 1px solid ${({ theme }) => theme.input.border.default};
  background: linear-gradient(108.18deg, #FFFFFF -3.32%, rgba(255, 255, 255, 0.5) 102.86%);
  box-shadow: 0 4px 4px rgba(171, 181, 209, 0.2);
  border-radius: 20px;
  padding: 14px 24px;
  width: 100%;
  outline: none;
  font-family: 'Cera Pro', sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: ${({ theme }) => theme.input.text};
  transition: ${({ theme }) => theme.generateTransition('border')};
  
  ${({ theme, hasError }) => (hasError ? `
    border: 1px solid ${theme.input.border.error};
  ` : `
    &:hover {
      border: 1px solid ${theme.input.border.hover};
    }
    
    &:focus {
      border: 1px solid ${theme.input.border.active};
    }
  `)}
`;

export const Textarea = styled.textarea<{ hasError: boolean }>`
  border: 1px solid ${({ theme }) => theme.input.border.default};
  background: linear-gradient(108.18deg, #FFFFFF -3.32%, rgba(255, 255, 255, 0.5) 102.86%);
  box-shadow: 0 4px 4px rgba(171, 181, 209, 0.2);
  backdrop-filter: blur(6px);
  border-radius: 20px;
  padding: 14px 24px;
  width: 100%;
  outline: none;
  font-family: 'Cera Pro', sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: ${({ theme }) => theme.input.text};
  transition: .3s ease-out;
  resize: none;

  ${({ theme, hasError }) => (hasError ? `
    border: 1px solid ${theme.input.border.error};
  ` : `
    &:hover {
      border: 1px solid ${theme.input.border.hover};
    }
    
    &:focus {
      border: 1px solid ${theme.input.border.active};
    }
  `)}
`;

export const Error = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.input.error};
  
  &:not(:empty) {
    margin-top: 8px;
  }
`;
