import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
`;

export const TableWrapper = styled.div`
  width: 100%;
  flex: 1 1 auto;

  @media (min-width: 768px) {
    background: linear-gradient(288.5deg, rgba(255, 255, 255, 0.5) -23.92%, rgba(255, 255, 255, 0.3) 103.97%),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
    border: 1px solid;
    border-image-source: linear-gradient(288.5deg, rgba(255, 255, 255, 0.5) -23.92%, rgba(255, 255, 255, 0.3) 103.97%);
    box-shadow: 0 4px 4px 0 #ABB5D133;
    border-radius: 20px;
    padding: 32px 24px;
  }

  @media (min-width: 1024px) {
    padding: 32px;
  }
`;

export const Table = styled.table`
  width: 100%;

  th {
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    padding: 0 8px 18px 8px;

    @media (min-width: 768px) {
      font-size: 16px;
      padding: 0 8px 24px 8px;
    }

    @media (min-width: 1024px) {
      font-size: 20px;
    }
  }

  td {
    font-weight: 400;
    font-size: 13px;
    padding: 18px 8px;

    @media (min-width: 768px) {
      font-size: 16px;
      padding: 24px 8px;
    }
  }

  th:first-child,
  td:first-child {
    padding-left: 0;
  }

  th:last-child,
  td:last-child {
    padding-right: 0;
    text-align: right;

    @media (min-width: 768px) {
      text-align: left;
    }
  }

  @media (max-width: 767.9px) {
    tbody {
      tr:nth-child(odd) > td {
        padding-bottom: 5px;
      }

      tr:nth-child(even) > td {
        padding-top: 5px;
      }
    }
  }
`;

export const Status = styled.span<{ status: 'active' | 'inactive' | 'disabled' }>`
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme, status }) => theme.status.background[status]};
  padding: 3px 10px;
  border-radius: 8px;
  display: inline-block;
  text-align: center;

  @media (min-width: 1024px) {
    font-size: 14px;
    width: 114px;
    border-radius: 20px;
  }
`;

export const EditButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  width: 24px;
  height: 24px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 4px rgba(171, 181, 209, 0.2);
  border-radius: 4px;
  border: none;
  margin-left: 24px;
  opacity: 0;
  transition: ${({ theme }) => theme.generateTransition('opacity')};

  &:focus-visible {
    opacity: 1;
  }

  @media not all and (hover: hover) {
    opacity: 1;
  }
`;

export const NameCell = styled.td`
  word-break: break-word;

  @media (min-width: 1200px) {
    width: 40%;
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover ${EditButton} {
    opacity: 1;
  }
`;

export const NameForm = styled.div`
  position: relative;
`;

export const NameInput = styled.input`
  box-shadow: 0 4px 4px rgba(171, 181, 209, 0.2);
  border-radius: 4px;
  padding: 8px 80px 8px 8px;
  width: 100%;
  outline: none;
  font-family: 'Cera Pro', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.text};
  border: 1px solid ${({ theme }) => theme.input.border.default};
  background: ${({ theme }) => theme.colors.white};
  transition: ${({ theme }) => theme.generateTransition('border')};

  @media (min-width: 768px) {
    font-size: 16px;
    padding: 10px 80px 10px 16px;
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.input.border.hover};
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.input.border.active};
  }
`;

export const NameButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 36px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.input.border.active};
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    padding: 10px 80px 10px 16px;
    right: 48px;
  }

  & + button {
    right: 8px;

    @media (min-width: 768px) {
      right: 16px;
    }
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.text};
  }
`;
