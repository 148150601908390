import * as React from 'react';
import * as S from '@components/Pagination.styled';
import { getCookie } from '@common/functions';
import { useAuth } from '@src/app/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ChevronLeft from '@src/images/chevron-left.svg';
import ChevronRight from '@src/images/chevron-right.svg';

type TProps = {
  itemsPerPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
}

function Pagination({
  itemsPerPage,
  setPage,
  page,
}: TProps): JSX.Element | null {
  const authContext = useAuth();
  const [maxPage, setMaxPage] = useState<number>(1);
  const token = getCookie('token');

  const fetchPageCount = useCallback(async (signal: AbortSignal) => {
    const response = await fetch(`${window.API_DOMAIN}/api/user/terminals`, {
      headers: {
        accept: 'application/ld+json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      signal,
    });

    if (response?.status === 401) {
      authContext?.onLogout();
    } else {
      const responseData = await response.json();
      setMaxPage(Math.ceil((responseData['hydra:totalItems'] as number) / itemsPerPage));
    }
  }, [authContext, itemsPerPage, token]);

  useEffect(() => {
    const abortController = new AbortController();
    fetchPageCount(abortController.signal);

    return () => {
      abortController.abort();
    };
  }, [fetchPageCount]);

  const paginationButtons = useMemo(() => {
    const buttons = [];
    for (let i = 1; i <= maxPage; i += 1) {
      buttons.push((
        <S.Button
          key={i}
          onClick={() => setPage(i)}
          isActive={page === i}
          type="button"
        >
          {i}
        </S.Button>
      ));
    }
    return buttons;
  }, [maxPage, page]);

  return (
    paginationButtons.length > 1 ? (
      <S.Pagination>
        <S.Button
          onClick={() => setPage(prevState => prevState - 1)}
          disabled={page === 1}
          type="button"
        >
          <img src={ChevronLeft} alt="ChevronLeft" />
        </S.Button>
        {paginationButtons}
        <S.Button
          onClick={() => setPage(prevState => prevState + 1)}
          disabled={page === maxPage}
          type="button"
        >
          <img src={ChevronRight} alt="ChevronRight" />
        </S.Button>
      </S.Pagination>
    ) : null
  );
}

export default Pagination;
