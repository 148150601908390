import * as React from 'react';
import * as S from '@components/Dropdown.styled';
import { TTerminal } from '@components/Monitoring';
import { closeModal, openModal } from '@src/redux/slices/DialogSlice';
import { useAppDispatch, useAuth, useOutsideAlerter } from '@src/app/hooks';
import { useCallback, useMemo, useRef, useState } from 'react';
import Button from '@ui/Button';
import Options from '@src/images/options.svg';

type TProps = {
  terminal: TTerminal,
};

function Dropdown({
  terminal,
}: TProps): JSX.Element {
  const authContext = useAuth();
  const dispatch = useAppDispatch();
  const [isActive, setIsActive] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setIsActive(false);
    }
  }, [wrapperRef]);
  useOutsideAlerter(wrapperRef, handleClickOutside);

  const sendCommand = useCallback(async (command: string) => {
    const response = await fetch(`${window.API_DOMAIN}/api/user/terminals/command/${terminal.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${authContext?.token}`,
      },
      body: JSON.stringify({
        command,
      }),
    });

    if (response?.status === 401) {
      authContext?.onLogout();
    } else {
      setIsActive(prevState => !prevState);
      const dialogID = `commandDialog-${terminal.id}`;
      dispatch(openModal({
        id: dialogID,
        body: (
          <S.CommandDialog>
            <S.TerminalTitle>{command === 'auraRestart' ? 'Программное обеспечение' : 'Терминал'}</S.TerminalTitle>
            <S.TerminalName>{command === 'auraRestart' ? 'BM Aura' : terminal.name}</S.TerminalName>
            <S.TerminalInfo>{command === 'terminalOff' ? 'Выключается...' : 'Перезагружается...'}</S.TerminalInfo>
            <Button text="Ok" onClick={() => dispatch(closeModal(dialogID))} padding={67} />
          </S.CommandDialog>
        ),
        properties: {
          width: 555,
        },
        showCloseButton: false,
        onOpen: () => setTimeout(() => {
          dispatch(closeModal(dialogID));
        }, 5000),
      }));
    }
  }, [authContext, dispatch, terminal.id, terminal.name]);

  const isDisabled = useMemo(() => (
    !terminal.terminalOnline || !!terminal.command
  ), [terminal.command, terminal.terminalOnline]);

  return (
    <S.Options isActive={isActive} ref={wrapperRef}>
      <S.Toggler onClick={() => setIsActive(prevState => !prevState)}>
        <img src={Options} alt="" />
      </S.Toggler>
      <S.Dropdown>
        <S.Button
          disabled={isDisabled}
          type="button"
          onClick={() => sendCommand('terminalRestart')}
        >
          Перезапустить терминал
        </S.Button>
        <S.Button
          disabled={isDisabled}
          type="button"
          onClick={() => sendCommand('auraRestart')}
        >
          Перезапустить BM Aura
        </S.Button>
        <S.Button
          disabled={isDisabled}
          type="button"
          onClick={() => sendCommand('terminalOff')}
        >
          Отключить терминал
        </S.Button>
      </S.Dropdown>
    </S.Options>
  );
}

export default Dropdown;
