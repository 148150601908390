import { ExitButton as BaseExitButton } from '@ui/ExitButton';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const ExitButton = styled(BaseExitButton)`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    align-self: flex-start;
  }

  @media (min-width: 1024px) {
    align-self: stretch;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  
  @media (min-width: 768px) {
    height: calc(100vh - 17.6vh);
    width: 224px;
    align-items: stretch;
    flex-direction: column;
    flex: 0 0 auto;
    position: sticky;
    top: 8.8vh;
    margin: 0;
  }

  @media (min-width: 1024px) {
    width: 261px;
  }
`;

export const Link = styled(NavLink)`
  background: rgba(255, 255, 255, 0.15);
  border-radius: 16px;
  padding: 12px;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  transition: .3s ease-out;
  
  &:hover,
  &.active {
    background: linear-gradient(108.18deg, rgba(255, 255, 255, 0.5) -3.32%, rgba(255, 255, 255, 0) 102.86%),
      linear-gradient(288.5deg, rgba(255, 255, 255, 0.5) -23.92%, rgba(255, 255, 255, 0.3) 103.97%);
    border-image-source: linear-gradient(288.5deg, rgba(255, 255, 255, 0.5) -23.92%, rgba(255, 255, 255, 0.3) 103.97%);
    box-shadow: inset 0 4px 4px rgba(255, 255, 255, 0.2), 0 4px 4px rgba(171, 181, 209, 0.2);
  }

  &+& {
    margin-left: 16px;

    @media (min-width: 768px) {
      margin-top: 24px;
      margin-left: 0;
    }
  }

  @media (min-width: 768px) {
    padding: 16px 32px;
    border-radius: 83px;
  }
`;

export const CurrentPage = styled.div`
  font-weight: 700;
  font-size: 17px;
  margin-left: 16px;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const LinkText = styled.span`
  font-weight: 300;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text};
  display: none;

  @media (min-width: 768px) {
    display: block;
  }

  @media (min-width: 1024px) {
    display: block;
    font-size: 20px;
  }
`;

export const LinkIcon = styled.img.attrs(() => ({
  width: 24,
  height: 24,
}))`
  @media (min-width: 768px) {
    margin-right: 16px;
  }
`;
