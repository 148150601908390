import * as React from 'react';
import * as S from './Login.styled';
import { Navigate } from 'react-router-dom';
import { openModal } from '@src/redux/slices/DialogSlice';
import { selectConfig } from '@src/redux/slices/ConfigSlice';
import { useAppDispatch, useAppSelector, useAuth } from '@src/app/hooks';
import { useState } from 'react';
import Button from '@ui/Button';
import Checkbox from '@ui/Checkbox';
import Input from '@ui/Input';
import Logo from '@src/images/logo.svg';

export type TLoginFormData = {
  login: {
    value: string,
    error: string,
  },
  password: {
    value: string,
    error: string,
  },
  rememberMe: {
    value: boolean,
    error: string,
  },
  authError: string,
}

const defaultData = {
  login: {
    value: '',
    error: '',
  },
  password: {
    value: '',
    error: '',
  },
  rememberMe: {
    value: false,
    error: '',
  },
  authError: '',
};

function Login(): JSX.Element {
  const authContext = useAuth();
  const dispatch = useAppDispatch();
  const config = useAppSelector(selectConfig);
  const [data, setData] = useState<TLoginFormData>(defaultData);

  if (authContext?.token) {
    return <Navigate to="/" replace />;
  }

  return (
    <S.Wrapper>
      <S.Logo src={Logo} alt="Aura" />
      <S.Form
        onSubmit={event => {
          event.preventDefault();
          authContext?.onLogin?.(data, setData);
        }}
      >
        <S.Title>Вход в учетную запись</S.Title>
        <S.FormItem>
          <Input
            placeholder="Логин"
            name="login"
            type="text"
            value={data.login.value}
            error={data.login.error}
            onChange={event => setData(prevState => ({
              ...prevState,
              ...{
                login: {
                  value: event.target.value,
                  error: '',
                },
                authError: '',
              },
            }))}
          />
        </S.FormItem>
        <S.FormItem>
          <Input
            placeholder="Пароль"
            name="password"
            type="password"
            value={data.password.value}
            error={data.password.error}
            onChange={event => setData(prevState => ({
              ...prevState,
              ...{
                password: {
                  value: event.target.value,
                  error: '',
                },
                authError: '',
              },
            }))}
          />
        </S.FormItem>
        {data.authError && <S.Error>{data.authError}</S.Error>}
        <S.ResetLink
          href="/reset-password"
          onClick={event => {
            event.preventDefault();
            const dialogID = 'resetPassword';
            dispatch(openModal({
              id: dialogID,
              body: <S.ResetDialog dangerouslySetInnerHTML={{ __html: config.messageResetPwd }} />,
              properties: {
                width: 848,
              },
              showCloseButton: true,
            }));
          }}
        >
          Забыли пароль?
        </S.ResetLink>
        <Checkbox
          id="loginCheckbox"
          label="Запомнить меня"
          onChange={event => setData(prevState => ({
            ...prevState,
            ...{
              rememberMe: {
                value: event.target.checked,
                error: '',
              },
            },
          }))}
        />
        <Button text="Вход" padding={57} type="submit" />
      </S.Form>
    </S.Wrapper>
  );
}

export default Login;
