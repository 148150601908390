import * as React from 'react';
import * as S from '@ui/ExitButton.styled';
import Exit from '@src/images/exit.svg';

type TProps= {
  onClick?: () => void;
  className?: string;
}

export function ExitButton({ onClick, className }: TProps): JSX.Element {
  return (
    <S.ExitButton className={className} onClick={onClick}>
      <S.LinkIcon src={Exit} alt="Выход" />
      <span>Выход</span>
    </S.ExitButton>
  );
}
