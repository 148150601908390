import * as React from 'react';
import * as S from './Input.styled';
import { ChangeEvent } from 'react';

type TProps = {
  name?: string;
  type?: string;
  placeholder?: string;
  value?: string;
  error?: string;
  multiline?: boolean,
  rows?: number;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

function Input({
  name,
  type,
  placeholder,
  rows,
  value = '',
  error = '',
  multiline = false,
  onChange,
}: TProps): JSX.Element {
  return (
    <div>
      {multiline ? (
        <S.Textarea
          name={name}
          placeholder={placeholder}
          hasError={!!error}
          rows={rows}
          value={value}
          onChange={onChange}
        />
      ) : (
        <S.Input
          name={name}
          placeholder={placeholder}
          hasError={!!error}
          type={type}
          value={value}
          onChange={onChange}
        />
      )}
      <S.Error>{error}</S.Error>
    </div>
  );
}

export default Input;
