import * as React from 'react';
import * as S from '@ui/Checkbox.styled';
import { ChangeEvent } from 'react';

type IProps = {
  id: string;
  label: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

function Checkbox({
  id,
  onChange,
  label,
}: IProps): JSX.Element {
  return (
    <S.Wrapper>
      <S.Input id={id} type="checkbox" onChange={onChange} />
      <S.Label htmlFor={id}>{label}</S.Label>
    </S.Wrapper>
  );
}

export default Checkbox;
