import { createGlobalStyle } from 'styled-components';
import bg from './images/bg.jpg';

export const GlobalStyles = createGlobalStyle`
  *, *:after, *:before {
    box-sizing: border-box;
  }

  html, body, #root {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  body {
    font-family: 'Cera Pro', sans-serif;
    font-size: 16px;
    line-height: 1.25;
    background-image: url("${bg}");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    overflow-y: scroll;
    color: ${({ theme }) => theme.colors.text};
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.text};
  }

  img {
    max-width: 100%;
    object-fit: contain;
    user-drag: none;
    user-select: none;
  }

  button {
    cursor: pointer;
  }

  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.input.placeholder};
    opacity: 1;
  }

  :-moz-placeholder,
  ::-moz-placeholder {
    color: ${({ theme }) => theme.input.placeholder};
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: ${({ theme }) => theme.input.placeholder};
    opacity: 1;
  }

  ::placeholder {
    color: ${({ theme }) => theme.input.placeholder};
    opacity: 1;
  }

  .decoration {
    position: fixed;
    z-index: -1;

    &.bottom-left {
      bottom: -60px;
      left: 0;
      width: 23.7vw;
      min-width: 320px;

      @media (min-width: 768px) {
        bottom: 0;
      }
    }

    &.bottom-middle {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 31.7vw;
    }

    &.bottom-right {
      bottom: 50px;
      right: 0;
      width: 15.3vw;
    }

    &.top-left {
      top: 0;
      left: 0;
      width: 29.6vw;
      min-width: 320px;
    }

    &.top-right {
      top: -30px;
      right: -20px;
      width: 20.2vw;
      min-width: 200px;

      @media (min-width: 768px) {
        top: 0;
        right: 0;
      }
    }
  }
`;
