import { AppThunk, RootState } from '@src/redux/store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type TConfigState = {
  timeRefreshData: number;
  messageResetPwd: string;
}

const initialState: TConfigState = {
  timeRefreshData: 5,
  messageResetPwd: '',
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfig: (state, action: PayloadAction<TConfigState>) => {
      state.timeRefreshData = action.payload.timeRefreshData;
      state.messageResetPwd = action.payload.messageResetPwd;
    },
  },
});

export const { setConfig } = configSlice.actions;
export const selectConfig = (state: RootState): TConfigState => state.config;

export const fetchConfig = (signal: AbortSignal): AppThunk => async dispatch => {
  const response = await fetch(`${window.API_DOMAIN}/api/config`, { signal });
  if (response.status === 200) {
    dispatch(setConfig(await response.json()));
  }
};

export default configSlice.reducer;
