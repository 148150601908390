import { Route, Routes } from 'react-router-dom';
import { fetchConfig } from '@src/redux/slices/ConfigSlice';
import { useAppDispatch } from '@src/app/hooks';
import AuthProvider from '@src/app/AuthProvider';
import BottomLeft from './images/bottom-left.svg';
import BottomMiddle from './images/bottom-middle.svg';
import BottomRight from './images/bottom-right.svg';
import Dialog from '@components/Dialog';
import Login from '@components/Login';
import Monitoring from '@components/Monitoring';
import React, { useEffect } from 'react';
import Study from '@components/Study';
import Support from '@components/Support';
import TopLeft from './images/top-left.svg';
import TopRight from './images/top-right.svg';

function App(): JSX.Element {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const abortController = new AbortController();
    dispatch(fetchConfig(abortController.signal));
    return () => {
      abortController.abort();
    };
  }, [dispatch]);

  return (
    <AuthProvider>
      <img className="decoration bottom-middle" src={BottomMiddle} alt="" />
      <img className="decoration bottom-right" src={BottomRight} alt="" />
      <img className="decoration top-left" src={TopLeft} alt="" />
      <img className="decoration top-right" src={TopRight} alt="" />
      <img className="decoration bottom-left" src={BottomLeft} alt="" />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/study" element={<Study />} />
        <Route path="/support" element={<Support />} />
        <Route path="/" element={<Monitoring />} />
        <Route path="*" element={<div>Not found</div>} />
      </Routes>
      <Dialog />
    </AuthProvider>
  );
}

export default App;
