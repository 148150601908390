import styled from 'styled-components';

export const Wrapper = styled.div`
  &+& {
    margin-top: 48px;
  }
`;

export const Iframe = styled.object`
  display: block;
  width: 100%;
  aspect-ratio: 16/9;
  border: 1px solid #FFF;
  box-shadow: 0 4px 4px rgba(171, 181, 209, 0.2);
  border-radius: 20px;
`;

export const Footer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

export const Button = styled.a`
  background: linear-gradient(108.18deg, rgba(255, 255, 255, 0.5) -3.32%, rgba(255, 255, 255, 0) 102.86%),
    linear-gradient(288.5deg, rgba(255, 255, 255, 0.5) -23.92%, rgba(255, 255, 255, 0.3) 103.97%);
  border: 1px solid;
  border-image-source: linear-gradient(288.5deg, rgba(255, 255, 255, 0.5) -23.92%, rgba(255, 255, 255, 0.3) 103.97%);
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 20px;
  padding: 16px 32px;
  box-shadow: inset 0 4px 4px rgba(255, 255, 255, 0.2), 0 4px 4px rgba(171, 181, 209, 0.2);
  border-radius: 83px;
`;
