import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@src/redux/store';
import { setModalCloseStyles, setModalOpenStyles } from '@common/functions';

export type TDialogProperties = {
  width: number;
}

export type TDialog = {
  id: string;
  body?: JSX.Element,
  properties: TDialogProperties,
  showCloseButton: boolean,
  onOpen?: () => void,
}

export type TDialogState = {
  data: Array<TDialog>;
}

const initialState: TDialogState = {
  data: [],
};

export const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<TDialog>) => {
      setModalOpenStyles();
      state.data.push(action.payload);
    },
    closeModal: (state, action: PayloadAction<string>) => {
      setModalCloseStyles();
      state.data = state.data.filter(dialog => dialog.id !== action.payload);
    },
    closeAllModal: state => {
      setModalCloseStyles();
      state.data = [];
    },
  },
});

export const { openModal, closeModal, closeAllModal } = dialogSlice.actions;
export const selectDialog = (state: RootState): Array<TDialog> => state.dialog.data;

export default dialogSlice.reducer;
