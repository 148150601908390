import { TToken } from '@src/app/types';

/**
 * Заменяет все вхождения указанной подстроки на новую подстроку.
 * @param {string} inputString - Исходная строка.
 * @param {string} substring - Подстрока, которую нужно заменить.
 * @param {string} newSubstring - Подстрока, на которую нужно заменить.
 * @returns {string} Строка с заменёнными подстроками.
 */
export const replaceSubstrings = (inputString: string, substring: string, newSubstring: string): string => (
  inputString?.split(substring).join(newSubstring)
);

/**
 * Форматирует строку с описателями типа {0}.
 * @param {string} inputString - Исходная строка.
 * @param {...string} values - Значения для подстановки.
 * @returns {string} Отформатированная строка.
 */
export function formatString(inputString: string, ...values: Array<string>): string {
  if (!inputString || !values.length) return inputString;
  let result = inputString;
  values.forEach((value, index) => { result = replaceSubstrings(result, `{${index}}`, value); });

  return result;
}

/**
 * Возвращает склонение числительного для числа.
 * @param {number} value - Число.
 * @param {Array<string>} words - Массив склонений.
 * @returns {string} Склонение.
 */
export function pluralize(value: number, words: Array<string>): string {
  const cases = [2, 0, 1, 1, 1, 2];
  const index = (value % 100 > 4 && value % 100 < 20) ? 2 : cases[Math.min(value % 10, 5)];

  return words[index];
}

/**
 * Возвращает Cookie с указанным именем.
 * @param {string} name - Имя.
 * @returns {string|undefined} Cookie.
 */
export function getCookie(name: string): string | null {
  const matches = document.cookie.match(new RegExp(
    `(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`,
  ));
  return matches ? decodeURIComponent(matches[1]) : null;
}

/**
 * Устанавливает Cookie.
 * @param {string} name - Имя.
 * @param {string} value - Значение.
 * @param {Record<string, unknown>} options - Опции.
 */
export function setCookie(name: string, value: string, options: Record<string, unknown> = {}): void {
  options = {
    path: '/',
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires?.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  for (const optionKey in options) {
    updatedCookie += `; ${optionKey}`;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`;
    }
  }

  document.cookie = updatedCookie;
}

/**
 * Удаляет Cookie.
 * @param {string} name - Имя.
 */
export function deleteCookie(name: string): void {
  setCookie(name, '', {
    'max-age': -1,
  });
}

/**
 * Убирает скроллбар при открыти модального окна.
 */
export function setModalOpenStyles(): void {
  const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
  const body = document.querySelector('body');
  if (body !== null) {
    body.style.overflowY = 'hidden';
    body.style.paddingRight = `${scrollbarWidth}px`;
  }
}

/**
 * Возвращает скроллбар при закрытии модального окна.
 */
export function setModalCloseStyles(): void {
  const body = document.querySelector('body');
  if (body !== null) {
    body.style.overflowY = 'scroll';
    body.style.paddingRight = String(0);
  }
}

export function decodeJWT(token: string): TToken {
  return JSON.parse(window.atob(token.split('.')[1]));
}
