import * as React from 'react';
import * as S from './Button.styled';

type IProps = {
  text: string;
  padding: number;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
}

function Button({
  text,
  onClick,
  padding,
  type = 'button',
}: IProps): JSX.Element {
  return (
    <S.Button
      onClick={onClick}
      padding={padding}
      type={type}
    >
      {text}
    </S.Button>
  );
}

export default Button;
