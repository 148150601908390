import * as React from 'react';
import * as S from '@components/Menu.styled';
import { useAuth } from '@src/app/hooks';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import Book from '@src/images/book.svg';
import Screen from '@src/images/screen.svg';
import Support from '@src/images/support.svg';

function Menu(): JSX.Element {
  const authContext = useAuth();
  const location = useLocation();

  const currentPage = useMemo(() => {
    if (location.pathname === '/') {
      return 'Мониторинг';
    } if (location.pathname === '/study') {
      return 'Обучение';
    } if (location.pathname === '/support') {
      return 'Тех. поддержка';
    }

    return '';
  }, [location]);

  return (
    <S.Wrapper>
      <S.Link to="/">
        <S.LinkIcon src={Screen} alt="Мониторинг" />
        <S.LinkText>Мониторинг</S.LinkText>
      </S.Link>
      <S.Link to="/study">
        <S.LinkIcon src={Book} alt="Обучение" />
        <S.LinkText>Обучение</S.LinkText>
      </S.Link>
      <S.Link to="/support">
        <S.LinkIcon src={Support} alt="Тех. поддержка" />
        <S.LinkText>Тех. поддержка</S.LinkText>
      </S.Link>
      <S.CurrentPage>{currentPage}</S.CurrentPage>
      <S.ExitButton onClick={authContext?.onLogout} />
    </S.Wrapper>
  );
}

export default Menu;
