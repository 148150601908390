import { ExitButton as BaseExitButton } from '@ui/ExitButton';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1760px;
  padding: 32px 16px;
  margin: 0 auto;
  min-height: 100%;

  @media (min-width: 768px) {
    align-items: stretch;
    flex-direction: row;
    padding: 8.8vh 16px;
  }
`;

export const Content = styled.div`
  flex: 1 1 auto;
  margin-bottom: 66px;
  
  @media (min-width: 768px) {
    margin-left: 32px;
    margin-bottom: 0;
  }
`;

export const ExitButton = styled(BaseExitButton)`
  align-self: flex-start;
  
  @media (min-width: 768px) {
    display: none;
  }
`;
