import { DefaultTheme } from 'styled-components';
import { formatString } from '@common/functions';

const White = '#fff';
const WhiteSmoke = '#EEEEEE';
const Gainsboro = '#DCDCDC';
const DarkGray = '#B0B0B0';
const SuvaGrey = '#909090';
const FuscousGrey = '#484748';
const PastelGreen = '#72DB82';
const VividTangerine = '#FF8F80';
const Cinnabar = '#E95732';
const transition = '{0} .3s ease-out';

/**
 * Генерирует значение для transition из переданных CSS свойств.
 * @param {...string} values - CSS свойства.
 * @returns {string} Значение для transition.
 */
const generateTransition = (...values: Array<string>): string => values.map(value => formatString(transition, value)).join(',');

export const theme: DefaultTheme = {
  colors: {
    white: White,
    text: FuscousGrey,
    error: Cinnabar,
  },
  input: {
    text: FuscousGrey,
    placeholder: DarkGray,
    error: Cinnabar,
    border: {
      default: White,
      hover: Gainsboro,
      active: DarkGray,
      error: Cinnabar,
    },
  },
  status: {
    background: {
      active: PastelGreen,
      inactive: VividTangerine,
      disabled: Gainsboro,
    },
  },
  dropdown: {
    text: FuscousGrey,
    background: {
      default: White,
      hover: WhiteSmoke,
      active: DarkGray,
    },
  },
  pagination: {
    text: {
      default: SuvaGrey,
      active: White,
    },
    background: {
      default: 'transparent',
      active: 'linear-gradient(213.13deg, #E95732 -6.21%, #FBC549 106.79%)',
    },
  },
  generateTransition,
};
