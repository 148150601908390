import styled from 'styled-components';

export const Toggler = styled.button`
  width: 48px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(288.5deg, rgba(255, 255, 255, 0.5) -23.92%, rgba(255, 255, 255, 0.3) 103.97%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
  border: 1px solid;
  border-image-source: linear-gradient(288.5deg, rgba(255, 255, 255, 0.5) -23.92%, rgba(255, 255, 255, 0.3) 103.97%);
  border-radius: 20px;
  transition: .3s ease-out;
  
  &:hover {
    background: linear-gradient(288.5deg, rgba(255, 255, 255, 0.5) -23.92%, rgba(255, 255, 255, 0.3) 103.97%),
      linear-gradient(0deg, #FFFFFF, #FFFFFF);
  }
`;

export const Dropdown = styled.div`
  position: absolute;
  z-index: 10;
  right: 0;
  top: calc(100% + 12px);
  padding: 16px 8px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  box-shadow: 0 4px 4px rgba(171, 181, 209, 0.2);
  opacity: 0;
  pointer-events: none;
  transition: .3s ease-out;
`;

export const Button = styled.button`
  font-family: 'Cera Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  min-width: 220px;
  color: ${({ theme }) => theme.colors.text};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  padding: 8px 16px;
  border: none;
  transition: .3s ease-out;
  
  &+& {
    margin-top: 12px;
  }
  
  &:hover {
    background: #EEEEEE;
  }
  
  &:active {
    background: #B0B0B0;
  }
  
  &:disabled {
    color: ${({ theme }) => theme.colors.text};
    background: ${({ theme }) => theme.colors.white};
    opacity: .7;
    cursor: not-allowed;
  }
`;

export const Options = styled.div<{ isActive: boolean }>`
  position: relative;
  display: inline-block;
  
  ${({ isActive }) => (isActive ? `
    ${Dropdown} {
      opacity: 1;
      pointer-events: auto;
    }
    ${Toggler} {
      background: linear-gradient(288.5deg, rgba(255, 255, 255, 0.5) -23.92%, rgba(255, 255, 255, 0.3) 103.97%),
        linear-gradient(0deg, #FFFFFF, #FFFFFF);
    }
  ` : '')}
`;

export const CommandDialog = styled.div`
  text-align: center;
`;

export const TerminalTitle = styled.div`
  font-weight: 300;
  margin-bottom: 8px;
`;

export const TerminalName = styled.div`
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 8px;
`;

export const TerminalInfo = styled.div`
  font-weight: 700;
  color: #E95732;
  margin-bottom: 24px;
`;
